














































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { HumanEfficiencyEntityModel, HumanEfficiencyQueryModel } from '@common-src/entity-model/human-efficiency-entity';
import HumanEfficiencyService from '@common-src/service/human-efficiency';

@Component
export default class WorkOrderRateListComponent extends TableDialogFormComponent<HumanEfficiencyEntityModel, HumanEfficiencyQueryModel> {
    HumanEfficiencyEntityModel = HumanEfficiencyEntityModel;

    created() {
        this.initTable({
            service: HumanEfficiencyService,
            queryModel: new HumanEfficiencyQueryModel(),
            tableColumns: HumanEfficiencyEntityModel.getTableColumns()
        });

        this.getList();
    }
}
