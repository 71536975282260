import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel, FormControlModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import CommonService from '@common-src/service/common';
import { PropertyEntityType } from '@common-src/model/enum';

const applicantOptions = [{ name: '创建人', value: 'Creator' }, { name: '申请人', value: 'Applicant' }, { name: '结束人', value: 'End' }];

export class HumanEfficiencyEntityModel extends BaseEntityModel {
    @FormControl({
        label: '人效分组名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlOptionModel)
    name: string = undefined;

    @FormControl({
        label: '编号',
        type: FormControlType.TEXT,
        required: true,
        readonly: true
    } as FormControlOptionModel)
    identifier: string = undefined;

    @FormControl({
        label: '关联对象',
        type: FormControlType.SELECT_USER
    } as FormControlModel<any>)
    linkers: Array<string> = undefined;

    orgs: Array<string> = undefined;
    users: Array<string> = undefined;
    // @FormControl({
    //     label: '关联对象',
    //     type: FormControlType.SELECT_USER,
    //     mode: 'multiple',
    //     required: true
    // } as FormControlOptionModel)
    // users: Array<string> = undefined;
    // orgs:Array<string> = undefined;

    @FormControl({
        label: '说明',
        type: FormControlType.TEXT_AREA,
        required: false
    } as FormControlTextModel)
    remark: string = undefined;

    linkList: Array<string> = [];

    static getTableColumns() {
        return [
            {
                title: '人效分组名称',
                dataIndex: 'name'
            },
            {
                title: '人效编号',
                dataIndex: 'identifier'
            },
            {
                title: '关联对象',
                dataIndex: 'linkList',
                scopedSlots: { customRender: 'linkList' }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        this.orgs = _.get(json, 'orgs');
        this.users = _.map(_.get(json, 'users'), item => {
            item.isUser = true;
            return item;
        });
        this.linkers = _.union(this.orgs, this.users);
        return this;
    }

    toService() {
        const data: any = super.toService();
        const users = [];
        const groups = [];
        if (data.linkers && data.linkers.length > 0) {
            data.linkers.forEach(user => {
                if (user.isUser) {
                    users.push({
                        id: user.id,
                        name: user.name
                    });
                } else {
                    groups.push({
                        id: user.id,
                        name: user.name
                    });
                }
            });
        }
        data.users = [];
        data.users = users;
        data.orgs = groups;
        delete data.linkers;
        return data;
    }
}

export class HumanEfficiencyQueryModel extends QueryPageModel {
    keyWords: string = undefined;

    toService() {
        const data: any = super.toService();
        return data;
    }
}
